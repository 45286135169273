$background: #202b38 !default;
$background-alt: #161f27 !default;

$text-main: #dbdbdb !default;
$text-bright: #ffffff !default;

$links: #41adff !default;
$focus: #0096bfab !default;
$border: #dbdbdb !default;
$code: #ffbe85 !default;

$button-hover: #324759 !default;
$animation-duration: 0.1s !default;

$scrollbar-thumb: $button-hover;
$scrollbar-thumb-hover: lighten($button-hover, 8%);

$form-placeholder: #a9a9a9 !default;
$form-text: #ffffff !default;

@import 'parts/core';